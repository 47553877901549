<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        id="formRole001"
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="reinitForm"
        v-if="roleData != null"
      >
        <b-row style="margin-bottom: 10px">
          <b-col cols="8">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Role
            </h6>
          </b-col>

          <b-col cols="4">
            <b-button
              v-if="$router.name == 'role-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'role-view',
                  params: { id: roleData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'role-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'role-view',
                  params: { id: roleData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'role-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des Roles</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="invoice-add">
          <!-- name -->
          <b-col cols="12" xl="12" md="12">
            <b-card no-body class="invoice-preview-card">
              <b-row class="">
                <!-- Name -->
                <b-col cols="6" md="4">
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-group label="Code Role" label-for="name">
                      <b-form-input
                        id="name"
                        name="name"
                        v-model="roleData.name"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: admin, it-assistant, superviseur, securite,..."
                        :readonly="readOnlyUniqueName"
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Le nom de role, seulement les letres de: aA-zZ
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="4">
                  <validation-provider
                    #default="validationContext"
                    name="level"
                    rules="required"
                  >
                    <b-form-group label="Niveau d'acces" label-for="level">
                      <b-form-input
                        id="level"
                        name="level"
                        v-model="roleData.level"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: 6,5,4,..."
                        :readonly="readOnlyUniqueName"
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer le niveau d'accès s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="4">
                  <b-form-group
                    label="Description du rôle"
                    label-for="description"
                  >
                    <b-form-input
                      id="description"
                      name="description"
                      v-model="roleData.description"
                      trim
                      placeholder="Ex: Gestionnaire Principal,..."
                      :readonly="readOnlyUniqueName"
                      class=""
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- DEPARTEMENT / PERMISSION TABLE -->
        <div
          style="
            padding: 5px;
            margin-bottom: 10px;
            color: red;
            border: 1px dashed red;
          "
          v-if="treeData == null"
        >
          Chargement des permissions
        </div>

        <b-card-title class="font-medium-2">
          <feather-icon icon="UserIcon" size="18" />
          <span class="align-middle ml-50">Permissions liées au Rôle</span>
        </b-card-title>

        <!-- search input -->
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model.lazy="searchword"
            placeholder="Recherche permissions... Ex: Identifier Agent"
            @input="search"
          />
        </b-input-group>

        <b-row>
          <b-col cols="12" md="12">
            <!-- tree -->
            <v-tree
              ref="tree"
              :can-delete-root="false"
              :data="treeData"
              :draggable="false"
              :tpl="tpl"
              :halfcheck="true"
              :multiple="true"
              style="margin-left: 10px"
            />
          </b-col>
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />
              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="roleData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// sweet alert
import { codeBasic } from "../../extensions/sweet-alert/code";
// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"; 
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";

import { VTree } from "vue-tree-halower";
import { codeSearch } from "./code";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BFormSelect,
  BFormDatepicker,
  VBToggle,
  BMedia,
  BAvatar,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "role";

function isset(ref) {
  return typeof ref !== "undefined";
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    BFormSelect,
    BFormDatepicker,
    vSelect,
    Logo,
    VTree,
    BCardCode,
    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      defaultRoleData: null,
      readOnlyUniqueName: false,
      expandedTreeView: false,
      actionIndex: 0,
      showed: true,
      loading: false,
      tableFields: [
        {
          key: "module",
          label: "MODULE",
          sortable: true,
          thStyle: { width: "40%" },
        },
        {
          key: "route",
          label: "URL",
          thClass: "d-none",
          tdClass: "d-none",
          thStyle: { width: "0%" },
        },
        {
          key: "read",
          label: "LIRE",
          variant: "success",
          thStyle: { width: "15%" },
        },
        {
          key: "write",
          label: "ECRIRE",
          variant: "primary",
          thStyle: { width: "15%" },
        },
        {
          key: "update",
          label: "MODIFIER",
          variant: "warning",
          thStyle: { width: "15%" },
        },
        {
          key: "delete",
          label: "SUPPRIMER",
          variant: "danger",
          thStyle: { width: "15%" },
        },
        {
          key: "folder",
          label: "Folder",
          thStyle: { width: "15%" },
        },
      ],

      codeSearch,
      searchword: "",
      treeData: [],
    };
  },
  props: {
    roleData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    ///////////////////////////////////////////////////////////
    // console
    // this.items = this.items.map((item) => ({ ...item, isEdit: false }));
    ///////////////////////////////////////////////////////////

    if (router.currentRoute.name == "role-add-new") {
      this.operationTitle = "Nouveau ";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "role-view") {
      this.operationTitle = "Détails du ";
      this.operationIcon = "EyeIcon";
      this.expandedTreeView = true;
    }
    if (router.currentRoute.name == "role-edit") {
      this.operationTitle = "Modification du ";
      this.operationIcon = "EditIcon";
      this.expandedTreeView = true;
    }

    if (router.currentRoute.name == moduleName + "-add-new") {
      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == moduleName + "-edit") {
      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }

    /* if (router.currentRoute.name == moduleName + "-add-new") {
      // while add new
      this.readOnlyUniqueName = false;
      this.getDepartmentsPermissions("1", true);
    } else {
      this.readOnlyUniqueName = true;
      // edit
      this.getDepartmentsPermissions(router.currentRoute.params.id, false);
    } */

    // offline get
    const userData = JSON.parse(localStorage.getItem("userData"));
    var menus = userData.departmentData;

    for (let m_module_i = 0; m_module_i < menus.length; m_module_i++) {
      /* if (isset(module.list) && module.list == true) {

      } */

      var main_module = menus[m_module_i];

      var sub_modules = main_module.modules;

      var sub_modules_output = [];
      for (
        let sm_module_i = 0;
        sm_module_i < sub_modules.length;
        sm_module_i++
      ) {
        // sub modules
        var sub_module = sub_modules[sm_module_i];

        if (main_module.route == "dashboard") {
          sub_modules_output.push({
            title: sub_module.module,
            expanded: true,
            children: [
              {
                title: "Visionner",
                value: "view " + sub_module.ressource,
              },
            ],
          });
        } else {
          sub_modules_output.push({
            title: sub_module.module,
            expanded: true,
            children: [
              {
                title: "Ajouter",
                value: "add " + sub_module.ressource,
              },
              {
                title: "Modifier",
                value: "edit " + sub_module.ressource,
              },
              {
                title: "Visionner",
                value: "view " + sub_module.ressource,
              },
              {
                title: "Lister",
                value: "list " + sub_module.ressource,
              },
            ],
          });
        }
      }

      this.treeData.push({
        title: main_module.name,
        expanded: this.expandedTreeView,
        children: sub_modules_output,
      });
    }
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    search() {
      this.$refs.tree.searchNodes(this.searchword);
    },
    tpl(...args) {
      const { 0: node, 2: parent, 3: index } = args;
      let titleClass = node.selected
        ? "node-title node-selected"
        : "node-title";
      if (node.searched) titleClass += " node-searched";
      return (
        <span>
          <span
            class={titleClass}
            domPropsInnerHTML={node.title}
            onClick={() => {
              this.$refs.tree.nodeSelected(node);
            }}
          ></span>
          <buttonlawyer-add-new
            class="btn-delete text-danger border-0 cursor-pointer"
            onClick={() => this.$refs.tree.delNode(node, parent, index)}
          >
            <feather-icon icon="XIcon" size="10" /> Rétirer
          </buttonlawyer-add-new>
        </span>
      );
    },
    async asyncLoad(node) {
      const { checked = false } = node;
      this.$set(node, "loading", true);
      const pro = new Promise((resolve) => {
        setTimeout(resolve, 2000, ["async node1", "async node2"]);
      });
      this.$refs.tree.addNodes(node, await pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },

    editRowHandler(data) {
      this.items[data.index].isEdit = !this.items[data.index].isEdit;
    },
    reinitForm() {
      ///////////////////////////////////////////////////////
      this.roleData = this.defaultRoleData;
      this.roleData.name = "";
      this.roleData.level = "";
      this.roleData.description = "";
      this.roleData.permissions = this.defaultRoleData.permissions;
    },
    getDepartmentsPermissions(roleID, fromDepartment) {
      //
      var userData = null;
      var permissions = null;
      //

      if (roleID != "") {
        if (fromDepartment == true) {
          roleID == 0;
        }
        // get from web
        axios
          .get(
            $themeConfig.app.api_endpoint +
              `get_` +
              moduleName +
              `_infos/${$themeConfig.app.api_endpoint_access_token}/${roleID}`
          )
          .then((response) => {
            var roleData = response.data.data;

            console.log("roleData");
            console.log(roleData);

            var roleName = roleData.name;
            var level = roleData.level;
            var description = roleData.description;

            var rolePermissions = JSON.parse(roleData.permissions);
            console.log(rolePermissions);

            var i = 0;
            // boucle des departements
            for (i; i < rolePermissions.length; i++) {
              // boucle des modules
              var module_index = 0;
              var modulesPrepared = [];

              for (
                module_index;
                module_index <
                JSON.parse(JSON.stringify(rolePermissions[i].modules)).length;
                module_index++
              ) {
                var currentpermissionModules = JSON.parse(
                  JSON.stringify(rolePermissions[i].modules)
                );

                // console.log(currentpermissionModules);

                // add to array
                modulesPrepared.push({
                  module: currentpermissionModules[module_index].module,
                  ressource: currentpermissionModules[module_index].ressource,
                  read: currentpermissionModules[module_index].read,
                  write: currentpermissionModules[module_index].write,
                  update: currentpermissionModules[module_index].update,
                  delete: currentpermissionModules[module_index].delete,
                  folder: currentpermissionModules[module_index].folder,
                });
              }
              // fin boucle modules
              rolePermissions[i].modules = modulesPrepared;
            }

            //
            this.$props.roleData.name = roleName;
            this.$props.roleData.level = level;
            this.$props.roleData.description = description;
            this.$props.roleData.permissions = JSON.parse(
              JSON.stringify(rolePermissions)
            );

            // finalize //
            this.defaultRoleData = this.$props.roleData;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // offline get
        userData = JSON.parse(localStorage.getItem("userData"));
        permissions = JSON.parse(userData.departmentData);

        var i = 0;
        // boucle des departements
        for (i; i < permissions.length; i++) {
          // boucle des modules
          var module_index = 0;
          var modulesPrepared = [];
          for (
            module_index;
            module_index <
            JSON.parse(JSON.stringify(permissions[i].modules)).length;
            module_index++
          ) {
            var currentpermissionModules = JSON.parse(
              JSON.stringify(permissions[i].modules)
            );
            // add to array
            modulesPrepared.push({
              module: currentpermissionModules[module_index].module,
              ressource: currentpermissionModules[module_index].ressource,
              read: true,
              write: true,
              update: true,
              delete: true,
              folder: currentpermissionModules[module_index].folder,
            });
          }
          // fin boucle modules
          permissions[i].modules = modulesPrepared;
        }

        //
        this.$props.roleData.name = "";
        this.$props.roleData.permissions = JSON.parse(
          JSON.stringify(permissions)
        );

        // finalize
        this.defaultRoleData = this.$props.roleData;
      }
    },
    formReadyForSubmission() {
      // validation check condition
      this.errors = [];
      if (this.$props.roleData.name == "") {
        this.errors.push("Compléter le nom de role svp.");
      }
      if (this.errors.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    async addUpdateItemAction(event) { 

      const defaultActionText = this.actionName;

      let formData = new FormData();
      if (router.currentRoute.name == "role-add-new") {
        this.actionName = "Enregistrement...";
      }
      if (router.currentRoute.name == "role-view") {
        formData.append("id", router.currentRoute.params.id);
      }
      if (router.currentRoute.name == "role-edit") {
        this.actionName = "Modification...";
        formData.append("id", router.currentRoute.params.id);
      }

      // finalise form data
      formData.append("name", this.$props.roleData.name);
      formData.append("level", this.$props.roleData.level);
      formData.append("description", this.$props.roleData.description);
      formData.append("permissions", JSON.stringify(this.treeData) );
      //

      let method = "";
      let params = "";

      if (router.currentRoute.name == "role-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "role-view") {
        method = "get";
      }
      if (router.currentRoute.name == "role-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.roleData.id;
      } 

      myApi({
        url: "role" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          console.log(response);

          if (response.status == 200 || response.status == 201) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "role-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "BellIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          this.actionName = defaultActionText;
          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "role-view" });
    },
    goToNextItem() {
      router.push({ name: "role-list" });
    },
    goToList() {
      router.push({ name: "role-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-role";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetmoduleData = () => {
      this.reinitForm();
    };

    let { refFormObserver, getValidationState, reinitForm } =
      formValidation(resetmoduleData);
    //
    let selectedRoleData = ref([]);

    let typeOptions = [
      { label: "Matière Première", value: "first-way" },
      { label: "Produit Semi-Fini", value: "half-finished" },
      { label: "Produit Fini", value: "finished" },
      { label: "Produit Dérivé", value: "derived" },
      { label: "Consommables", value: "consummable" },
      { label: "Service", value: "service" },
      { label: "Autres", value: "other" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    return {
      refFormObserver,
      getValidationState,
      // resetForm,
      typeOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/tree.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
